/**
 * Displays text with a background image
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ImageBackground from 'components/ImageBackground'
import ContainerBadge from 'components/ContainerBadge'
import Box from 'components/Box'

const useStyles = makeStyles({
  card: {
    textAlign: 'center',
    maxWidth: 900,
    padding: '56px 76px',
    backgroundColor: 'rgba(256, 256, 256, 0.85)',
  },
  mobileCard: {
    height: 205,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  mobileText: {
    fontSize: 20,
  },
})

const ImageBanner = (props) => {
  const { src, text, badgeText } = props
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  if (matchesMobile) {
    return (
      <ContainerBadge fullWidth text={badgeText}>
        <ImageBackground src={src}>
          <div className={classes.mobileCard}>
            <Box padding="0px 20px 52px">
              <Typography variant="body1" className={classes.mobileText} color="textSecondary">{text}</Typography>
            </Box>
          </div>
        </ImageBackground>
      </ContainerBadge>
    )
  }

  return (
    <ImageBackground src={src}>
      <Box margin="100px 0px">
        <ContainerBadge text={badgeText}>
          <div className={classes.card}>
            <Typography variant="h1" color="textPrimary">{text}</Typography>
          </div>
        </ContainerBadge>
      </Box>
    </ImageBackground>
  )
}

ImageBanner.propTypes = {}
ImageBanner.defaultProps = {}

export default ImageBanner

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./bodoni-mt.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./BodoniFLF-Roman.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./Coves-Light.otf";
import ___CSS_LOADER_URL_IMPORT_3___ from "./Coves-Bold.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Bodoni MT;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}@font-face{font-family:Bodoni;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\")}@font-face{font-family:Coves-Light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"opentype\")}@font-face{font-family:Coves-Bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"opentype\")}a,a:visited{text-decoration:none;color:inherit}", "",{"version":3,"sources":["webpack://styles/app.css"],"names":[],"mappings":"AAAA,WAAW,qBAAqB,CAAC,8DAAyC,CAAC,WAAW,kBAAkB,CAAC,8DAA+C,CAAC,WAAW,uBAAuB,CAAC,8DAA2C,CAAC,WAAW,sBAAsB,CAAC,8DAA0C,CAAC,YAAY,oBAAoB,CAAC,aAAa","sourcesContent":["@font-face{font-family:Bodoni MT;src:url(bodoni-mt.ttf) format(\"truetype\")}@font-face{font-family:Bodoni;src:url(BodoniFLF-Roman.ttf) format(\"truetype\")}@font-face{font-family:Coves-Light;src:url(Coves-Light.otf) format(\"opentype\")}@font-face{font-family:Coves-Bold;src:url(Coves-Bold.otf) format(\"opentype\")}a,a:visited{text-decoration:none;color:inherit}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// displays the most popular posts in general
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Box from 'components/Box'
import List from 'components/List'
import Container from 'components/Container'
import Paper from 'components/Paper'

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      textAlign: 'left',
    },
  },
}))

const MostPopular = (props) => {
  const { posts } = props
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const mappedPosts = posts.map((x) => ({
    ...x,
    src: x.heroImage.file.url,
    badgeText: x.page,
  }))

  return (
    <Box
      margin="112px 0px 144px"
      xs={{ margin: '0px' }}
    >
      <Paper color="secondary">
        <Box
          padding="128px 0px"
          xs={{ padding: '24px 20px' }}
        >
          <Container>
            <Box
              margin="0px 0px 64px"
              xs={{ margin: '0px 0px 24px' }}
            >
              <Typography
                className={classes.title}
                variant="h2"
              >
                Most Popular
              </Typography>
            </Box>
            <List orientation="horizontal" data={mappedPosts} />
          </Container>
        </Box>
      </Paper>
    </Box>
  )
}

MostPopular.propTypes = {}
MostPopular.defaultProps = {}

export default MostPopular

/**
 * renders a list of HorizontalList
 */
import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Box from 'components/Box'
import HorizontalListItem from 'components/HorizontalListItem'
import VerticalListItem from 'components/VerticalListItem'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto',
  },
  item: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 245,
    },
  },
  divider: {
    margin: '24px 0px 16px',
  },
}))

const List = (props) => {
  const { data, orientation } = props
  const classes = useStyles()
  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const mapOrientationToProps = {
    horizontal: {
      direction: 'row',
      listItem: VerticalListItem,
      cols: {
        lg: 3,
        sm: 4,
        xs: 12,
      },
      spacing: matchesMobile ? 3 : 5,
    },
    vertical: {
      direction: 'column',
      listItem: HorizontalListItem,
      cols: {
        xs: 12,
      },
      spacing: 3,
    },
  }

  const ListProps = mapOrientationToProps[orientation]

  return (
    <Box padding={theme.spacing(ListProps.spacing / 2)}>
      <Grid
        container
        direction={ListProps.direction}
        spacing={ListProps.spacing}
        wrap="nowrap"
        className={classes.container}
      >
        {
          data.map(x => (
            <Grid key={x.id} item className={classes.item} {...ListProps.cols}>
              <ListProps.listItem {...x} />
              {matchesMobile && orientation === 'vertical' ? <Divider className={classes.divider} /> : null}
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

List.propTypes = {}
List.defaultProps = {}

export default List

// displays the recent and instagram posts
import React from 'react'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'

import Box from 'components/Box'
import Pagination from 'components/Pagination'
import Container from 'components/Container'
import List from 'components/List'

const RecentAndInstagramPosts = (props) => {
  const { page } = props
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const mapPostsToList = (posts) => posts.map((x) => ({
    ...x,
    src: x?.heroImage?.file?.url,
    badgeText: x.page,
  }))

  return (
    <Container>
      <Box margin="0px 0px 144px" xs={{ margin: '0px 0px 80px', padding: '0px 20px' }}>
        <Pagination page={page}>
          {
            ({ posts }) => (
              <Box padding="0px 32px" xs={{ padding: '0px' }}>
                <Grid container direction={matchesMobile ? 'column' : 'row'} spacing={matchesMobile ? 0 : 8}>
                  <Grid item md={matchesMobile ? 12 : 8}>
                    <List orientation="vertical" data={mapPostsToList(posts)} />
                  </Grid>
                </Grid>
              </Box>
            )
          }
        </Pagination>
      </Box>
    </Container>
  )
}

RecentAndInstagramPosts.propTypes = {}
RecentAndInstagramPosts.defaultProps = {}

export default RecentAndInstagramPosts

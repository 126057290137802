/**
 * Links to different parts of the app.
 */
import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import useRoutes from 'utils/useRoutes'

const useStyles = makeStyles((theme) => ({
  linkList: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      overflowX: 'auto',
    },
  },
  link: {
    color: theme.palette.secondary.light,
    margin: '20px 20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  activePath: {
    lineHeight: 1,
    fontFamily: 'BodoniMT',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: theme.palette.primary.dark,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}))

const NavigationBar = (props) => {
  const { activePath } = props
  const classes = useStyles()
  const routes = useRoutes()

  // filter out hidden routes
  const noHiddenRoutes = routes.filter(x => !x.hidden)

  return (
    <div>
      <Divider />
      <div className={classes.linkList}>
        {
          noHiddenRoutes.map(x => (
            <Link key={x.path} to={x.path}>
              <Typography
                variant="h6"
                className={clsx(
                  classes.link,
                  { [classes.activePath]: x.path.includes(activePath) && activePath !== '/' },
                )}
              >
                {x.label.toUpperCase()}
              </Typography>
            </Link>
          ))
        }
      </div>
      <Divider />
    </div>
  )
}

NavigationBar.propTypes = {
  activePath: PropTypes.string,
}

NavigationBar.defaultProps = {
  activePath: '/',
}

export default NavigationBar

// Lists all the components available
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Container from 'components/Container'
import Paper from 'components/Paper'

import Image from 'components/Image'
import ContainerBadge from 'components/ContainerBadge'
import ImageBanner from 'components/ImageBanner'
import AuthorInfo from 'components/AuthorInfo'
import VerticalListItem from 'components/VerticalListItem'
import HorizontalListItem from 'components/HorizontalListItem'
import FeaturedItem from 'components/FeaturedItem'
import Box from 'components/Box'
import List from 'components/List'
import Carousel from 'components/Carousel'
import Pagination from 'components/Pagination'

const useStyles = makeStyles({})

const MOCK_DATA = [
  {
    id: 1,
    to: '/',
    src: 'https://source.unsplash.com/tToIV2ZAWgE/800x600',
    avatar: 'https://source.unsplash.com/tToIV2ZAWgE/56x56',
    createdAt: new Date(),
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    previewText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    badgeText: 'TECHNOLOGY',
  },
  {
    id: 2,
    to: '/',
    src: 'https://source.unsplash.com/tToIV2ZAWgE/800x600',
    avatar: 'https://source.unsplash.com/tToIV2ZAWgE/56x56',
    createdAt: new Date(),
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    previewText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    badgeText: 'TECHNOLOGY',
  },
  {
    id: 3,
    to: '/',
    src: 'https://source.unsplash.com/tToIV2ZAWgE/800x600',
    avatar: 'https://source.unsplash.com/tToIV2ZAWgE/56x56',
    createdAt: new Date(),
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    previewText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    badgeText: 'TECHNOLOGY',
  },
  {
    id: 4,
    to: '/',
    src: 'https://source.unsplash.com/tToIV2ZAWgE/800x600',
    avatar: 'https://source.unsplash.com/tToIV2ZAWgE/56x56',
    createdAt: new Date(),
    title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    previewText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
    badgeText: 'TECHNOLOGY',
  },
]

const Elements = (props) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <div>
      <Container>
        <Typography variant="h1">Patterns</Typography>
        <Box margin="24px 0px 48px">
          <Typography variant="h4">Typography</Typography>
          <Typography variant="h1">H1 - {theme.typography.h1.fontSize}px</Typography>
          <Typography variant="h2">H2 - {theme.typography.h2.fontSize}px</Typography>
          <Typography variant="h3">H3 - {theme.typography.h3.fontSize}px</Typography>
          <Typography variant="h4">H4 - {theme.typography.h4.fontSize}px</Typography>
          <Typography variant="h5">H5 - {theme.typography.h5.fontSize}px</Typography>
          <Typography variant="h6">h6 - {theme.typography.h6.fontSize}px</Typography>
          <Typography variant="subtitle1">Subtitle1 - {theme.typography.subtitle1.fontSize}px</Typography>
          <Typography variant="subtitle2">Subtitle2 - {theme.typography.subtitle2.fontSize}px</Typography>
          <Typography variant="body1">Body1 - {theme.typography.body1.fontSize}px</Typography>
          <Typography variant="body2">Body2 - {theme.typography.body2.fontSize}px</Typography>
          <Typography variant="button">Button - {theme.typography.button.fontSize}px</Typography>
        </Box>
        <Typography variant="h4">Carousel</Typography>
        <Carousel>
          <ImageBanner
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed"
            badgeText="Badge 1"
          />
          <ImageBanner
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed"
            badgeText="Badge 2"
          />
          <ImageBanner
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed"
            badgeText="Badge 3"
          />
        </Carousel>
        {/* <Box margin="48px 0px">
          <Typography variant="h4">Pagination Vertical List</Typography>
          <Pagination>
            {
              ({ posts }) => (
                <List orientation="vertical" data={posts} />
              )
            }
          </Pagination>
        </Box> */}
      </Container>
      {/* <Box margin="48px 0px">
        <Container>
          <Typography variant="h4">Page Banner</Typography>
          <ImageBanner
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed"
            badgeText="Badge Test"
          />
        </Container>
      </Box>
      <Box margin="48px 0px">
        <Container>
          <Typography variant="h4">Featured Item</Typography>
          <FeaturedItem
            to="/"
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            avatar="https://source.unsplash.com/tToIV2ZAWgE/56x56"
            createdAt={new Date()}
            title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
            previewText="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
            bodyPreview="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
            badgeText="TECHNOLOGY"
          />
        </Container>
      </Box>
      <Container>
        <Box margin="48px 0px">
          <Typography variant="h4">Horizontal List</Typography>
          <List orientation="horizontal" data={MOCK_DATA} />
        </Box>
        <Box margin="48px 0px">
          <Typography variant="h4">Vertical List</Typography>
          <List orientation="vertical" data={MOCK_DATA} />
        </Box>
        <Typography variant="h1">Components</Typography>
        <Box margin="48px 0px">
          <Typography variant="h4">Image</Typography>
          <Image
            width={200}
            height={400}
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
          />
        </Box>
        <Box margin="48px 0px">
          <Typography variant="h4">Image with Card</Typography>
          <ImageBanner
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed"
            badgeText="Badge"
          />
        </Box>
        <Box margin="48px 0px">
          <Typography variant="h4">Image with Badge Text</Typography>
          <ContainerBadge text="Badge Test">
            <Image
              width={200}
              height={400}
              src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            />
          </ContainerBadge>
        </Box>
        <Typography variant="h4">Author Information</Typography>
        <AuthorInfo
          avatar="https://source.unsplash.com/tToIV2ZAWgE/56x56"
          name="Jane Doe"
          createdAt={new Date()}
        />
        <Box margin="48px 0px">
          <Typography variant="h4">Vertical List Item</Typography>
          <VerticalListItem
            to="/"
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            avatar="https://source.unsplash.com/tToIV2ZAWgE/56x56"
            createdAt={new Date()}
            title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
            previewText="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
            badgeText="TECHNOLOGY"
          />
        </Box>
        <Box margin="48px 0px">
          <Typography variant="h4">Horizontal List Item</Typography>
          <HorizontalListItem
            to="/"
            src="https://source.unsplash.com/tToIV2ZAWgE/800x600"
            avatar="https://source.unsplash.com/tToIV2ZAWgE/56x56"
            createdAt={new Date()}
            title="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
            previewText="Lorem ipsum dolor sit amet, consetetur sadipscing elitr."
            badgeText="TECHNOLOGY"
          />
        </Box>
      </Container>
      <Box margin="48px 0px">
        <Container>
          <Typography variant="h4">Paper with Background Colour</Typography>
        </Container>
        <Paper color="secondary">
          <Container>
            <Box padding="24px 0px">
              <Typography>Elements go here</Typography>
            </Box>
          </Container>
        </Paper>
      </Box> */}
    </div>
  )
}

Elements.propTypes = {}
Elements.defaultProps = {}

export default Elements

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from 'components/Box'
import Container from 'components/Container'

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.dark,
  },
}))

const Header = ({ page }) => {
  const classes = useStyles()

  return (
    <Box margin="24px 0px 48px" xs={{ margin: '24px 0px', padding: '0px 20px' }}>
      <Container>
        <Typography variant="h3" className={classes.title}>{page}</Typography>
      </Container>
    </Box>
  )
}

Header.propTypes = {}
Header.defaultProps = {}

export default Header

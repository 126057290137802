// displays all the assets in Contentful
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Box from 'components/Box'
import Container from 'components/Container'
import SemanticImage from 'components/SemanticImage'
import { VIDEO } from './BlogBlocks'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    flex: 1,
  },
  image: {
    height: 300,
  },
})

const Gallery = (props) => {
  const { assets } = props
  const classes = useStyles()

  const mappedAssets = assets.map(x => ({
    id: x.sys.id,
    title: x.fields.title,
    file: x.fields.file,
  }))

  return (
    <Container>
      <Box padding={16} xs={{ padding: 0 }}>
        <Grid container wrap="wrap" spacing={4}>
          {
            mappedAssets.map((asset) => (
              <Grid key={asset.id} item xs={12} sm={6} md={4} lg={3}>
                {
                  asset.file.contentType.includes('image') ? (
                    <SemanticImage
                      src={asset.file.url}
                      alt={asset.title}
                      className={classes.image}
                      popup
                    />
                  ) : (
                    <VIDEO file={asset.file} />
                  )
                }
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Container>
  )
}

Gallery.propTypes = {}
Gallery.defaultProps = {}

export default Gallery

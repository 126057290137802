import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { actions } from '../../app.module'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import FeaturedPost from './FeaturedPost'
import HorizontalPosts from './HorizontalPosts'
import RecentAndInstagramPosts from '../Landing/RecentAndInstagramPosts'
import Gallery from './Gallery'
import Header from './Header'

const CuratedPage = (props) => {
  const { page } = props
  const dispatch = useDispatch()
  const posts = useSelector(({ app }) => app.posts)
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  useEffect(() => {
    const loadPosts = async () => {
      await dispatch(actions.fetchBlogPosts(page))
    }

    loadPosts()
  }, [])

  return (
    <div>
      <Header page={page} />
      <FeaturedPost posts={posts} />
      <HorizontalPosts posts={posts} />
      <RecentAndInstagramPosts page={page} />
      {/* {matchesMobile ? null : <Gallery />} */}
    </div>
  )
}

CuratedPage.propTypes = {}
CuratedPage.defaultProps = {}

export default CuratedPage

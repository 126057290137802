/**
 * Configured Carousel component with custom dots
 */
import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Box from 'components/Box'

import ReactCarousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

const useStyles = makeStyles(theme => ({
  dot: {
    width: 18,
    height: 18,
    borderRadius: 9,
    backgroundColor: theme.palette.primary.main,
    margin: '0px 20px',
    transition: 'width 500ms ease-in-out',
    [theme.breakpoints.down('xs')]: {
      width: 5,
      height: 5,
      margin: '0px 2.5px',
      borderRadius: 4,
    },
  },
  active: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.primary.dark,
      width: 24,
    },
  },
}))

const Carousel = (props) => {
  const { children } = props
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const onChange = (val) => {
    setValue(val)
  }

  const getNumDots = () => {
    if (!children) return 0 // no children

    if (Array.isArray(children)) return children.length // >1 child

    return 1 // children is a single element
  }

  const numDots = getNumDots()

  return (
    <div>
      <Box
        margin="0px 0px 64px"
        xs={{ margin: '0px 0px 12px' }}
      >
        <ReactCarousel
          value={value}
          onChange={onChange}
        >
          {children}
        </ReactCarousel>
      </Box>
      <Box xs={{ margin: '0px 0px 15px' }}>
        <Grid container justify="center">
          {
            new Array(numDots).fill(0).map((x, i) => (
              // eslint-disable-next-line
              <Grid key={i} item>
                <IconButton onClick={() => { onChange(i) }} size="small">
                  <div
                    className={clsx(
                      classes.dot,
                      { [classes.active]: i === value },
                    )}
                  />
                </IconButton>
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </div>
  )
}

Carousel.propTypes = {}
Carousel.defaultProps = {}

export default Carousel

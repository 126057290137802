// displays the most popular posts in general
import React from 'react'
import PropTypes from 'prop-types'

import Box from 'components/Box'
import List from 'components/List'
import Container from 'components/Container'
import Paper from 'components/Paper'

const MostPopular = (props) => {
  const { posts } = props

  const mappedPosts = posts.map((x) => ({
    ...x,
    src: x.heroImage.file.url,
    badgeText: x.page,
  }))

  return (
    <Box margin="0px 0px 144px" xs={{ margin: '24px 0px' }}>
      <Paper color="secondary">
        <Box padding="106px 0px 128px" xs={{ padding: '24px 0px' }}>
          <Container>
            <List orientation="horizontal" data={mappedPosts} />
          </Container>
        </Box>
      </Paper>
    </Box>
  )
}

MostPopular.propTypes = {}
MostPopular.defaultProps = {}

export default MostPopular

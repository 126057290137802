import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Container from 'components/Container'
import Button from 'components/Button'
import GlobalSearch from 'components/GlobalSearch'
import NavigationBar from 'components/NavigationBar'

const useStyles = makeStyles(theme => ({
  appBar: {
    margin: '64px 0px 50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '30px 20px',
    },
  },
  accentText: {
    color: theme.palette.primary.dark,
  },
  headerText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontFamily: 'Bodoni MT',
    },
  },
}))

const Header = (props) => {
  const { location } = props
  const classes = useStyles()
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Container>
      <div className={classes.appBar}>
        {
          matchesMobile
            ? <div>Icon</div>
            : <Button variant="contained" color="primary">Subscribe</Button>
        }
        <Link to="/">
          <Typography className={classes.headerText} variant="h3" color="secondary">Lease Club <Typography variant="h3" component="span" className={clsx(classes.accentText, classes.headerText)}>Luxury</Typography></Typography>
        </Link>
        <GlobalSearch />
      </div>
      <NavigationBar activePath={location.pathname} />
    </Container>
  )
}

Header.propTypes = {}
Header.defaultProps = {}

export default Header

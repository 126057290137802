/**
 * This component handles loading paginated data from Contentful.
 * The posts in this component are independant of the posts in the redux store.
 */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PaginationControls from '@material-ui/lab/Pagination'
import Box from 'components/Box'

import contentful from 'utils/contentful'
import mapEntriesToPosts from 'utils/mapEntriesToPosts'

const useStyles = makeStyles(theme => ({
  controls: {
    '& button': {
      fontFamily: 'Coves-Bold',
      fontSize: 28,
      width: 56,
      height: 56,
      borderRadius: 28,
      margin: '0px 10px',
    },
    '&&&& button.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const Pagination = (props) => {
  const { children, page, limit } = props
  const [pageNum, setPageNum] = useState(0)
  const [posts, setPosts] = useState([])
  const classes = useStyles()

  // for now, pagination is independant so it requires same filtering logic
  useEffect(() => {
    const getEntries = async () => {
      const { items } = await contentful.getEntries({
        skip: pageNum * limit,
      })

      let mappedPosts = mapEntriesToPosts(items)

      if (page) {
        mappedPosts = mappedPosts.filter((x) => x.page === page)
      }

      setPosts(mappedPosts)
    }

    getEntries()
  }, [pageNum])

  const onChange = (newPageNum) => {
    setPageNum(newPageNum)
  }

  return (
    <div>
      {
        children({ pageNum, posts, onChange })
      }
      <Box margin="144px 0px 0px" xs={{ margin: '64px 0px 0px' }}>
        <Grid container justify="center">
          <Grid item>
            <PaginationControls
              classes={{ root: classes.controls }}
              page={pageNum + 1}
              count={Math.ceil(posts.length / limit)}
              onChange={(e, pg) => {
                onChange(pg - 1)
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

Pagination.propTypes = {
  limit: PropTypes.number,
}

Pagination.defaultProps = {
  limit: 5,
}

export default Pagination

/**
 * Adds margins around a component.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  box: ({ margin, padding, xs, sm, md, lg, xl }) => ({
    margin: margin ?? '0px',
    padding: padding ?? '0px',
    [theme.breakpoints.down('xs')]: {
      margin: xs.margin ?? margin,
      padding: xs.padding ?? padding,
    },
    [theme.breakpoints.up('sm')]: {
      margin: sm.margin ?? margin,
      padding: sm.padding ?? padding,
    },
    [theme.breakpoints.up('md')]: {
      margin: md.margin ?? margin,
      padding: md.padding ?? padding,
    },
    [theme.breakpoints.up('lg')]: {
      margin: lg.margin ?? margin,
      padding: lg.padding ?? padding,
    },
    [theme.breakpoints.up('xl')]: {
      margin: xl.margin ?? margin,
      padding: xl.padding ?? padding,
    },
  }),
}))

const Box = (props) => {
  const {
    children,
    margin,
    padding,
    xs,
    sm,
    md,
    lg,
    xl,
  } = props
  const classes = useStyles({
    margin,
    padding,
    xs,
    sm,
    md,
    lg,
    xl,
  })

  return (
    <div className={classes.box}>
      {children}
    </div>
  )
}

Box.propTypes = {
  xs: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  sm: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  md: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  lg: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  xl: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
}
Box.defaultProps = {
  xs: {
    margin: null,
    padding: null,
  },
  sm: {
    margin: null,
    padding: null,
  },
  md: {
    margin: null,
    padding: null,
  },
  lg: {
    margin: null,
    padding: null,
  },
  xl: {
    margin: null,
    padding: null,
  },
}

export default Box

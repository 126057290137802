// displays all the assets in Contentful
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Box from 'components/Box'
import Container from 'components/Container'
import Image from 'components/Image'
import { actions } from '../../app.module'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    flex: 1,
  },
})

const Gallery = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const assets = useSelector(({ app }) => app.assets)

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        await dispatch(actions.fetchAssets())
      } catch (err) {
        console.log(err)
      }
    }

    fetchAssets()
  }, [])

  return (
    <Container>
      <Box padding={16}>
        <Grid container wrap="wrap" spacing={4}>
          {
            assets.map((asset) => (
              <Grid key={asset.id} item xs={12} sm={6} md={4} lg={3}>
                <Image
                  src={asset.file.url}
                  width="100%"
                  height={300}
                  popup
                />
              </Grid>
            ))
          }
        </Grid>
      </Box>
    </Container>
  )
}

Gallery.propTypes = {}
Gallery.defaultProps = {}

export default Gallery

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { actions } from '../../app.module'

import HeroCarousel from './HeroCarousel'
import MostPopular from './MostPopular'
import FeaturedPost from './FeaturedPost'
import RecentAndInstagramPosts from './RecentAndInstagramPosts'

const useStyles = makeStyles({})

const Landing = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const loading = useSelector(({ app }) => app.loading)
  const posts = useSelector(({ app }) => app.posts)

  useEffect(() => {
    const loadPosts = async () => {
      await dispatch(actions.fetchBlogPosts())
    }

    loadPosts()
  }, [])

  return (
    <div>
      <HeroCarousel posts={posts} />
      <MostPopular posts={posts} />
      <FeaturedPost posts={posts} />
      <RecentAndInstagramPosts />
    </div>
  )
}

Landing.propTypes = {}
Landing.defaultProps = {}

export default Landing

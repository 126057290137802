// displays select posts in a carousel at the top of the page
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Box from 'components/Box'
import Carousel from 'components/Carousel'
import ImageBanner from 'components/ImageBanner'
import Container from 'components/Container'
import createPostURL from 'utils/createPostURL'

const useStyles = makeStyles({
  item: {
    width: '100%',
  },
})

const HeroCarousel = (props) => {
  const { posts } = props
  const classes = useStyles()

  return (
    <Box margin="24px 0px">
      <Container>
        <Carousel>
          {
            posts.map(x => (
              <div className={classes.item}>
                <Box xs={{ padding: '0px 20px' }}>
                  <Link key={x.id} to={createPostURL(x.id, x.title)}>
                    <ImageBanner
                      src={x.heroImage.file.url}
                      text={x.title}
                      badgeText={x.page}
                    />
                  </Link>
                </Box>
              </div>
            ))
          }
        </Carousel>
      </Container>
    </Box>
  )
}

HeroCarousel.propTypes = {}
HeroCarousel.defaultProps = {}

export default HeroCarousel

import { createMuiTheme } from '@material-ui/core/styles'

export const options = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    htmlFontSize: 16,
    useNextVariants: true,
    fontFamily: [
      'Coves-Bold',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: 56,
      fontFamily: 'Bodoni MT',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: 50,
      fontFamily: 'Bodoni MT',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 44,
      fontFamily: 'Bodoni',
      lineHeight: 1.4,
    },
    h4: {
      fontSize: 30,
      fontFamily: 'Coves-Bold',
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 24,
      fontFamily: 'Coves-Bold',
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 20,
      lineHeight: 1.15,
      fontFamily: 'Coves-Bold',
    },
    subtitle1: {
      fontSize: 22,
      fontFamily: 'Coves-Bold',
      lineHeight: 1.1,
    },
    subtitle2: {
      fontSize: 20,
      fontFamily: 'Coves-Light',
      lineHeight: 1.15,
    },
    body1: {
      fontSize: 16,
      fontFamily: 'Coves-Bold',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 18,
      fontFamily: 'Coves-Light',
      lineHeight: 1.3,
    },
    button: {
      fontSize: 18,
      fontFamily: 'Coves-Bold',
      lineHeight: 1.17,
    },
  },
  palette: {
    background: {
      paper: '#f9f7f1',
    },
    primary: {
      light: '#f9f7f1',
      main: '#ebcf8e',
      dark: '#904c02',
      contrastText: '#fff',
    },
    secondary: {
      light: '#535353',
      main: '#000',
      contrastText: '#fff',
    },
    text: {
      primary: '#070402',
      secondary: '#fff',
    },
  },
}

/**
 * One-off font styles
 * - active nav bar option
 */

export default createMuiTheme(options)

/**
 * Shows the blog post in a vertical hierarchy
 */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Image from 'components/Image'
import ImageBanner from 'components/ImageBanner'
import ContainerBadge from 'components/ContainerBadge'
import AuthorInfo from 'components/AuthorInfo'
import Box from 'components/Box'
import Button from 'components/Button'
import createPostURL from 'utils/createPostURL'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  image: {
    width: '100%',
    minWidth: '50%',
    maxWidth: 800,
  },
})

const FeaturedItem = (props) => {
  const { to, id, src, title, avatar, badgeText, previewText, bodyPreview, createdAt } = props
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const internalTo = createPostURL(id, title)

  if (matchesMobile) {
    return (
      <Link to={to || internalTo}>
        <Box margin="0px 0px 16px">
          <Typography variant="h5">{title}</Typography>
        </Box>
        <ImageBanner src={src} badgeText={badgeText} />
        <Box margin="16px 0px 0px">
          <AuthorInfo avatar={avatar} createdAt={createdAt} />
        </Box>
      </Link>
    )
  }

  return (
    <Link to={to || internalTo}>
      <div className={classes.root}>
        <div className={classes.image}>
          <Box margin="0px 60px 0px 0px">
            <ContainerBadge fullWidth text={badgeText}>
              <Image src={src} height={847} />
            </ContainerBadge>
          </Box>
        </div>
        <div>
          <Box margin="0px 0px 18px">
            <Typography variant="h4">Featured</Typography>
          </Box>
          <Divider />
          <Box margin="32px 0px">
            <Typography variant="h1">{title}</Typography>
          </Box>
          <AuthorInfo avatar={avatar} createdAt={createdAt} />
          <Box margin="16px 0px 0px">
            <Typography variant="body2">{previewText}</Typography>
          </Box>
          <Box margin="32px 0px 56px">
            <Typography variant="h4">{bodyPreview}</Typography>
          </Box>
          <Button variant="contained" color="primary">
            <Typography variant="subtitle1">View Post</Typography>
          </Button>
        </div>
      </div>
    </Link>
  )
}

FeaturedItem.propTypes = {}
FeaturedItem.defaultProps = {}

export default FeaturedItem

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  emailGridItem: {
    flex: 1,
  },
  emailField: {
    padding: '7px 20px',
    backgroundColor: '#fff',
    '& input::placeholder': { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: theme.palette.primary.main,
      opacity: 1, /* Firefox */
    },
  },
}))

const FORM_ACTION_URL = ''

const SubscriptionTextField = (props) => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [helperText, setHelperText] = useState('')
  const [subStatus, setSubStatus] = useState(null)

  useEffect(() => {
    if (subStatus === 'success') {
      const id = setTimeout(() => {
        setSubStatus(null)
        setEmail('')
        clearTimeout(id)
      }, 3500)
    }
  }, [subStatus])

  const validateEmail = () => {
    const regex = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/) // eslint-disable-line
    if (!regex.test(email)) {
      setHelperText('Please enter a valid email')

      const id = setTimeout(() => {
        setHelperText('')

        clearTimeout(id)
      }, 3500)
    }
  }

  const handleSubscriptionClick = (subscribe) => {
    setHelperText('')
    validateEmail(email)
    if (helperText) return

    try {
      subscribe({ EMAIL: email })
    } catch (err) {
      console.log(err)
    }
  }

  const onChange = (e) => {
    setEmail(e.target.value)
    setHelperText('')
  }

  const parseMessage = (status, m) => {
    if (m?.includes('already subscribed')) {
      return 'You are already subscribed!'
    }

    if (status === 'error') return 'Please try again later.'

    return m
  }

  if (subStatus === 'success') return <Typography color="secondary">Subscribed!</Typography>

  return (
    <MailchimpSubscribe
      url={FORM_ACTION_URL}
      render={
        ({ subscribe, status, message }) => {
          if (status === 'success') setSubStatus(status)

          return (
            <TextField
              fullWidth
              variant="standard"
              InputProps={{
                disableUnderline: true,
              }}
              placeholder="Enter email to subscribe"
              onChange={onChange}
              value={email}
              classes={{ root: classes.emailField }}
              helperText={helperText || parseMessage(status, message)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSubscriptionClick(subscribe)
                }
              }}
            />
          )
        }
      }
    />
  )
}

SubscriptionTextField.propTypes = {}
SubscriptionTextField.defaultProps = {}

export default SubscriptionTextField

/**
 * While the button styles are mostly from the theme, there are some
 * styles that have to be applied through classes.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ButtonTemplate from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 24px',
    boxShadow: 'none',
    borderRadius: 3,
    textTransform: 'none',
  },
}))

const Button = (props) => {
  const classes = useStyles()

  return (
    <ButtonTemplate
      classes={classes}
      {...props}
    />
  )
}

Button.propTypes = {}
Button.defaultProps = {}

export default Button

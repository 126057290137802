import React from 'react'
// import Loadable from 'react-loadable-visibility/react-loadable'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Typography from '@material-ui/core/Typography'
import contentful from 'utils/contentful'
import mapEntriesToPosts from 'utils/mapEntriesToPosts'
import mapAssetsToGallery from 'utils/mapAssetsToGallery'

import { HEADING_6, BODY, IMAGE, VIDEO } from './Pages/Blog/components/BlogBlocks'

// ------------------------------------
// Constants
// ------------------------------------
const LOAD_POSTS_REQUEST = 'LOAD_POSTS_REQUEST'
const LOAD_POSTS_SUCCESS = 'LOAD_POSTS_SUCCESS'
const LOAD_POSTS_ERROR = 'LOAD_POSTS_ERROR'

const LOAD_POST_REQUEST = 'LOAD_POST_REQUEST'
const LOAD_POST_SUCCESS = 'LOAD_POST_SUCCESS'
const LOAD_POST_ERROR = 'LOAD_POST_ERROR'

const LOAD_ASSETS_REQUEST = 'LOAD_ASSETS_REQUEST'
const LOAD_ASSETS_SUCCESS = 'LOAD_ASSETS_SUCCESS'
const LOAD_ASSETS_ERROR = 'LOAD_ASSETS_ERROR'

const initialState = {
  loading: true,
  posts: [],
  post: null,
  assets: [],
}

// ------------------------------------
// Actions
// ------------------------------------
const loadPostsRequest = () => ({
  type: LOAD_POSTS_REQUEST,
})

const loadPostsSuccess = posts => ({
  type: LOAD_POSTS_SUCCESS,
  posts,
})

const loadPostsError = () => ({
  type: LOAD_POSTS_ERROR,
})

const loadAssetsRequest = () => ({
  type: LOAD_ASSETS_REQUEST,
})

const loadAssetsSuccess = assets => ({
  type: LOAD_ASSETS_SUCCESS,
  assets,
})

const loadAssetsError = () => ({
  type: LOAD_ASSETS_ERROR,
})

const fetchBlogPosts = (page) => async (dispatch) => {
  dispatch(loadPostsRequest())

  try {
    const { items } = await contentful.getEntries({
      content_type: 'blogPost',
    })
    let posts = mapEntriesToPosts(items)

    if (page) {
      posts = posts.filter((x) => x.page === page)
    }

    dispatch(loadPostsSuccess(posts))
  } catch (err) {
    dispatch(loadPostsError())
    console.log(err)
  }
}


const fetchBlogPost = async (id) => {
  const options = {
    // https://www.npmjs.com/package/@contentful/rich-text-react-renderer
    // replace \n with <br />
    // renderText: text => {
    //   if (text === '') return [<br key={`${text}-key`} />]
    //   return text.split('\n').reduce((children, textSegment, index) => (
    //     [...children, index > 0 && <br key={index} />, textSegment] // eslint-disable-line
    //   ), [])
    // },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, file } = node?.data?.target?.fields

        if (file.contentType.includes('video')) {
          return <VIDEO file={file} />
        }

        if (file.contentType.includes('image')) {
          return <IMAGE src={file.url} width={file.details.image.width} height={file.details.image.height} alt={title} />
        }

        return <div>This asset could not be loaded. {title}</div>
      },
      [BLOCKS.HEADING_1]: (node, children) => <Typography variant="h1">{children}</Typography>,
      [BLOCKS.HEADING_2]: (node, children) => <Typography variant="h2">{children}</Typography>,
      [BLOCKS.HEADING_3]: (node, children) => <Typography variant="h3">{children}</Typography>,
      [BLOCKS.HEADING_6]: (node, children) => <HEADING_6>{children}</HEADING_6>,
      [BLOCKS.PARAGRAPH]: (node, children) => <BODY>{children}</BODY>,
    },
  }

  let post = null

  try {
    post = await contentful.getEntry(id)
    // parse rich text into React components for easy render
    post.fields.body = documentToReactComponents(post.fields.body, options)
  } catch (err) {
    console.log(err)
  }

  console.log(post)

  return post
}

const fetchAssets = (options = {}) => async (dispatch) => {
  dispatch(loadAssetsRequest())

  try {
    const assets = await contentful.getAssets(options)

    dispatch(loadAssetsSuccess(mapAssetsToGallery(assets.items)))
  } catch (err) {
    console.log(err)
    dispatch(loadAssetsError())
  }
}

export const actions = {
  fetchBlogPost,
  fetchBlogPosts,
  fetchAssets,
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOAD_POSTS_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [LOAD_POSTS_SUCCESS]: (state, { posts }) => ({
    ...state,
    loading: false,
    posts,
  }),
  [LOAD_POSTS_ERROR]: (state) => ({
    ...state,
    loading: false,
    posts: [],
  }),
  [LOAD_ASSETS_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [LOAD_ASSETS_SUCCESS]: (state, { assets }) => ({
    ...state,
    assets,
    loading: false,
  }),
  [LOAD_ASSETS_ERROR]: (state) => ({
    ...state,
    loading: false,
  }),
}
// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

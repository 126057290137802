import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles({})

const GlobalSearch = (props) => {
  const classes = useStyles()

  return (
    <div>
      <IconButton>
        <SearchIcon color="secondary" />
      </IconButton>
    </div>
  )
}

GlobalSearch.propTypes = {}
GlobalSearch.defaultProps = {}

export default GlobalSearch

import React, { useState } from 'react'
import fetch from 'node-fetch'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import PinDropIcon from '@material-ui/icons/PinDrop'
import EmailIcon from '@material-ui/icons/Email'
import Container from 'components/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 32,
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    display: 'block',
  },
  left: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(50% - 50px)',
    },
  },
  right: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(50% - 50px)',
      marginTop: 0,
    },
    marginTop: 50,
    paddingRight: 50,
  },
  inputLabel: {
    color: theme.palette.primary.dark,
  },
  address: {
    display: 'flex',
    justifyContent: 'start',
  },
  icon: {
    marginRight: 50,
  },
}))

const Contact = (props) => {
  const classes = useStyles()

  const [fullName, setFullName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [inquiry, setInquiry] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    const payload = new FormData()
    payload.append('name', fullName)
    payload.append('email', emailAddress)
    payload.append('inquiry', inquiry)

    const res = await fetch('https://goose.staccc.com:8087/lease-club/inquire/', {
      url: '',
      method: 'POST',
      headers: {
        // Authorization: process.env.AUTH_TOKEN,
      },
      body: payload,
    })

    if (!res.ok) {
      window.alert('Something went wrong on our side.')
    } else {
      window.alert('Your inquiry has been submitted.')
    }
  }

  return (
    <Container>
      <div className={classes.root}>
        <Typography variant="h3">Contact Us</Typography>
        <br />
        <br />
        <div className={classes.content}>
          <div className={classes.left}>
            <Typography variant="h7">We take our commitment to our customer seriously. If you need any help on advertising your business or organizing an event, please do not hesitate to contact us.</Typography>
            <br />
            <br />
            <Typography variant="h7">We look forward to hearing from you.</Typography>
            <br />
            <br />
            <div className={classes.address}>
              <div className={classes.icon}>
                <PinDropIcon fontSize="large" />
              </div>
              <div>
                <Typography variant="subtitle2">Solution Lease Club</Typography>
                <Typography variant="body2">11220 Voyageur Way</Typography>
                <Typography variant="body2">Richmond, BC</Typography>
                <Typography variant="body2">V6X 3E1</Typography>
              </div>
            </div>
            <br />
            <div className={classes.address}>
              <div className={classes.icon}>
                <EmailIcon fontSize="large" />
              </div>
              <div>
                <Typography variant="subtitle2"><a href="mailto:solutionleaseclub@gmail.com">solutionleaseclub@gmail.com</a></Typography>
              </div>
            </div>
          </div>
          <div className={classes.right}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <InputLabel className={classes.inputLabel}>Full name</InputLabel>
              <Input
                name="name"
                required
                value={fullName}
                onChange={(event) => setFullName(event.target.value)}
              />
              <br />
              <br />
              <br />
              <InputLabel className={classes.inputLabel}>Email address</InputLabel>
              <Input
                name="email"
                required
                value={emailAddress}
                onChange={(event) => setEmailAddress(event.target.value)}
              />
              <br />
              <br />
              <br />
              <InputLabel className={classes.inputLabel}>Message</InputLabel>
              <Input
                name="message"
                required
                multiline
                fullWidth
                rowsMax="3"
                value={inquiry}
                onChange={(event) => setInquiry(event.target.value)}
              />
              <br />
              <br />
              <br />
              <br />
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                Send
            </Button>
            </form>
          </div>
        </div>
      </div>
    </Container>
  )
}

Contact.propTypes = {}
Contact.defaultProps = {}

export default Contact

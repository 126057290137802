import React from 'react'
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom'
import Fade from '@material-ui/core/Fade'
import useRoutes from 'utils/useRoutes'

import Header from 'components/Header'
import Footer from 'components/Footer'
import CuratedPage from './Pages/CuratedPage'

const Routes = () => {
  const routes = useRoutes()

  return (
    <Router>
      <div>
        <Route component={Header} />
        <Switch>
          {
            routes.map(x => (
              <Route
                key={x.path}
                path={x.path}
                exact={x.exact}
                render={(props) => {
                  // if (!x.component) return <Redirect to="/" />
                  const Component = x.component || CuratedPage

                  const matchesPath = props.location.pathname === x.path
                  const isBlogPost = props.location.pathname.includes('/post')

                  return (
                    <Fade
                      in={matchesPath || isBlogPost}
                      timeout={500}
                      style={{ transitionDelay: '550ms' }}
                    >
                      <div>
                        <Component page={x.label} {...props} />
                      </div>
                    </Fade>
                  )
                }}
              />
            ))
          }
          <Redirect to="/" />
        </Switch>
        <Route component={Footer} />
      </div>
    </Router>
  )
}

export default Routes

/**
 * Shows the blog post in a vertical hierarchy
 */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import Image from 'components/Image'
import ContainerBadge from 'components/ContainerBadge'
import AuthorInfo from 'components/AuthorInfo'
import Box from 'components/Box'
import createPostURL from 'utils/createPostURL'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    cursor: 'pointer',
  },
  bookmarkIcon: {
    color: '#ebcf8e',
    marginRight: 10,
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
  },
  postDetails: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
}))

const HorizontalListItem = (props) => {
  const { to, id, src, title, avatar, badgeText, previewText, createdAt } = props
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const mobileTitleSubstring = title.substring(0, 45).concat('...')
  const internalTo = createPostURL(id, title)

  return (
    <Link to={to || internalTo}>
      <div className={classes.root}>
        <Box margin="0px 50px 0px 0px" xs={{ margin: '0px 20px 0px 0px' }}>
          {
            matchesMobile
              ? <Image src={src} width={98} height={98} />
              : (
                <ContainerBadge text={badgeText}>
                  <Image src={src} width={340} height={256} />
                </ContainerBadge>
              )
          }
        </Box>
        <div className={classes.postDetails}>
          <Box margin="0px 0px 32px" xs={{ margin: 0 }}>
            <Typography variant={matchesMobile ? 'subtitle1' : 'h4'}>{mobileTitleSubstring}</Typography>
          </Box>
          {
            matchesMobile
              ? (
                <div className={classes.badge}>
                  <BookmarkIcon className={classes.bookmarkIcon} />
                  <Typography>{badgeText}</Typography>
                </div>
              )
              : (
                <>
                  <Box margin="16px 0px">
                    <AuthorInfo avatar={avatar} createdAt={createdAt} />
                  </Box>
                  <Typography variant="body2">{previewText}</Typography>
                </>
              )
          }
        </div>
      </div>
    </Link>
  )
}

HorizontalListItem.propTypes = {}
HorizontalListItem.defaultProps = {}

export default HorizontalListItem

/**
 * Displays the author image, bio, and post creation date.
 *
 * TODO: read from a list of approved authors for the avatars and info
 */
import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: 56,
    height: 56,
    borderRadius: 28,
    marginRight: 20,
    backgroundColor: '#aaa',
    [theme.breakpoints.down('xs')]: {
      width: 32,
      height: 32,
      borderRadius: 16,
    }
  },
}))

const AuthorInfo = (props) => {
  const { avatar, name, createdAt } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img src={avatar} className={classes.avatar} alt={name} />
      <div>
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="subtitle2">{format(new Date(createdAt), 'dd MMMM yyyy')}</Typography>
      </div>
    </div>
  )
}

AuthorInfo.propTypes = {}
AuthorInfo.defaultProps = {}

export default AuthorInfo

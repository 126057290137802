// displays the featured post
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Box from 'components/Box'
import ImageBanner from 'components/ImageBanner'
import Container from 'components/Container'
import FeaturedItem from 'components/FeaturedItem'

const useStyles = makeStyles({})

const FeaturedPost = (props) => {
  const { posts } = props
  const classes = useStyles()

  const featuredPost = posts ? posts.find(x => x.featuredPost) : null

  return (
    <Container>
      <Box xs={{ padding: '0px 20px' }}>
        {
          featuredPost ? (
            <FeaturedItem
              {...featuredPost}
              to={`/post/${featuredPost.id}`}
              badgeText={featuredPost.page}
              src={featuredPost.heroImage.file.url}
            />
          ) : null
        }
      </Box>
    </Container>
  )
}

FeaturedPost.propTypes = {}
FeaturedPost.defaultProps = {}

export default FeaturedPost

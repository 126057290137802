/**
 * Loads an image source as a background-image that will center and cover
 * a container of any size
 */
import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  image: ({ popup }) => ({
    width: '100%',
    objectFit: 'cover',
    cursor: popup ? 'pointer' : 'auto',
  }),
  modalBody: {
    outline: 'none',
  },
  modalImage: ({ src }) => ({
    height: '95vh',
    width: '100%',
    marginTop: '2.5vh',
    backgroundImage: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  }),
  closeIcon: {
    position: 'fixed',
    top: 16,
    right: 16,
  },
}))

const SemanticImage = (props) => {
  const { src, alt, width, height, className, popup = false } = props
  const classes = useStyles({ src, width, height, popup })
  const [open, setOpen] = useState(false)

  const handleOnClick = () => {
    if (popup) {
      setOpen(true)
    }
  }

  const onClose = () => { setOpen(false) }

  return (
    <>
      {/* <div className={clsx(classes.root, className)} onClick={handleOnClick} /> */}
      <img src={src} alt={alt} className={clsx(classes.image, className)} onClick={handleOnClick} />
      <Modal
        open={open}
        onEscapeKeyDown={onClose}
      >
        <div className={classes.modalBody}>
          <div className={classes.modalImage} />
          <div className={classes.closeIcon}>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

SemanticImage.propTypes = {}
SemanticImage.defaultProps = {}

export default SemanticImage


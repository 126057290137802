/**
 * adds a background image and centers the content
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'components/Image'

const useStyles = makeStyles(theme => ({
  root: ({ position }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: position ?? 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  }),
  bgImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  children: {
    zIndex: 1,
    position: 'relative',
  },
}))

const ImageBanner = (props) => {
  const { src, position, children } = props
  const classes = useStyles({ position })

  return (
    <div className={classes.root}>
      <div className={classes.bgImage}>
        <Image src={src} />
      </div>
      <div className={classes.children}>
        {children}
      </div>
    </div>
  )
}

ImageBanner.propTypes = {}
ImageBanner.defaultProps = {}

export default ImageBanner

/**
 * Separates the content with a different background color that
 * spans the entire page width, but keeps the content contained.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  background: ({ color }) => {
    const map = {
      primary: theme.palette.background.default,
      secondary: theme.palette.background.paper,
    }

    return {
      backgroundColor: map[color],
    }
  },
}))

const Paper = (props) => {
  const { color, children } = props
  const classes = useStyles({ color })

  return (
    <div className={classes.background}>
      {children}
    </div>
  )
}

Paper.propTypes = {}
Paper.defaultProps = {}

export default Paper

/**
 * Shows the blog post in a vertical hierarchy
 */
import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Image from 'components/Image'
import ContainerBadge from 'components/ContainerBadge'
import AuthorInfo from 'components/AuthorInfo'
import Box from 'components/Box'
import ImageBanner from 'components/ImageBanner'
import createPostURL from 'utils/createPostURL'

const useStyles = makeStyles({
  fallbackRoot: {
    width: '100%',
  },
  root: {
    width: 'fit-content',
    cursor: 'pointer',
  },
})

const VerticalListItem = (props) => {
  const { to, id, src, title, avatar, badgeText, previewText, createdAt } = props
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const internalTo = createPostURL(id, title)

  if (matchesMobile) {
    const createMobileTitle = title.substring(0, 37).concat('...')

    return (
      <Link to={to || internalTo}>
        <div className={clsx(classes.root, classes.fallbackRoot)}>
          <ImageBanner
            src={src}
            badgeText={badgeText}
            text={createMobileTitle}
          />
          <Box margin="16px 0px">
            <AuthorInfo avatar={avatar} createdAt={createdAt} />
          </Box>
        </div>
      </Link>
    )
  }

  return (
    <Link to={to || internalTo}>
      <div className={clsx(classes.root, classes.fallbackRoot)}>
        <ContainerBadge fullWidth text={badgeText}>
          {/* have to define width (could be a bug) */}
          <Image src={src} height={256} />
        </ContainerBadge>
        <Box
          margin="40px 0px"
          xs={{ margin: '24px 0px' }}
        >
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Box margin="16px 0px">
          <AuthorInfo avatar={avatar} createdAt={createdAt} />
        </Box>
        <Typography variant="body2">{previewText}</Typography>
      </div>
    </Link>
  )
}

VerticalListItem.propTypes = {}
VerticalListItem.defaultProps = {}

export default VerticalListItem

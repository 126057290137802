import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#2d2d2d',
    padding: '54px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
}))

const Copyright = () => {
  const classes = useStyles()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <div className={classes.root}>
      <Typography align="center" variant={matchesMobile ? 'body1' : 'h5'} color="textSecondary">
        ©{new Date().getFullYear()} Lease Club Solution. All Rights Reserved. Powered and Designed by <a href="https://www.staccc.com">Staccc</a>.
      </Typography>
    </div>
  )
}

export default Copyright

/**
* @note
* array of posts
* struture of single post for list page:
* {
* title: String,
* description: String,
* createdAt: Date,
* heroImage: {
*    title: String,
*    file: { url, details, fileName, contentType },
* },
* tags: [],
* }
*/
const mapEntriesToPosts = (entries) => entries.map(({ sys, fields }) => ({
  ...fields,
  heroImage: fields.heroImage.fields,
  id: sys.id,
  createdAt: sys.createdAt,
  updatedAt: sys.updatedAt,
}))

export default mapEntriesToPosts

/**
 * Adds a text badge to the top right of the child container.
 * This component will expand to fill the width and height of the child container.
 */
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BookmarkIcon from '@material-ui/icons/Bookmark'

const useStyles = makeStyles(theme => ({
  parent: {
    position: 'relative',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      '&:after': {
        content: '""',
        bottom: 0,
        left: 0,
        width: '100%',
        position: 'absolute',
        height: '50%',
        backgroundImage: 'linear-gradient(to bottom, rgba(45, 45, 45, 0), #121212)',
        borderRadius: 10,
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '8px 24px',
    // backdrop requires slightly transparent color
    backgroundColor: 'rgba(144,76,2, 0.7)',
    backdropFilter: 'blur(7px)',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      // show the bookmark icon and text on the bottom left when on mobile
      top: 'unset',
      right: 'unset',
      backgroundColor: 'unset',
      backdropFilter: 'unset',
      bottom: 24,
      left: 20,
      display: 'flex',
      alignItems: 'center',
      padding: 0,
    },
  },
  bookmarkIcon: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      color: '#ebcf8e',
      marginRight: 10,
    },
  },
}))

const ContainerBadge = (props) => {
  const { text, fullWidth, children } = props
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.parent,
        // when children has no defined width
        { [classes.fullWidth]: fullWidth },
      )}
    >
      <div className={classes.badge}>
        <BookmarkIcon className={classes.bookmarkIcon} />
        <Typography color="textSecondary">{text}</Typography>
      </div>
      {children}
    </div>
  )
}

ContainerBadge.propTypes = {}
ContainerBadge.defaultProps = {}

export default ContainerBadge

/**
 * Sets the max width of the page content and centers it.
 * Includes spacing on smaller dimensions.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: theme.breakpoints.values.lg,
    margin: '0px auto',
    // [theme.breakpoints.down('lg')]: {
    //   padding: '0px 30px',
    // },
  },
}))

const Container = (props) => {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>{children}</div>
  )
}

Container.propTypes = {}
Container.defaultProps = {}

export default Container

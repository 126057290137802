import React from 'react' // temporary
import Landing from 'pages/Landing'
import Contact from 'pages/Contact'
import Elements from 'pages/Elements'
import Gallery from 'pages/Gallery'
import BlogPage from 'pages/Blog/BlogPage'

// used as page identifiers
export const HOMES = 'Homes'
export const AUTOMOTIVE = 'Automotive'
export const CULINARY = 'Culinary'
export const TECHNOLOGY = 'Technology'
export const TRAVEL = 'Travel'

// label is displayed to the user
const useRoutes = () => [
  {
    label: 'Landing',
    path: '/',
    component: Landing,
    exact: true,
    hidden: true,
  },
  {
    label: 'Elements',
    path: '/elements',
    component: Elements,
    hidden: true,
  },
  {
    label: AUTOMOTIVE,
    path: '/automotive',
    component: null,
  },
  {
    label: CULINARY,
    path: '/culinary',
    component: null,
  },
  {
    label: HOMES,
    path: '/homes',
    component: null,
  },
  {
    label: TECHNOLOGY,
    path: '/technology',
    component: null,
  },
  {
    label: TRAVEL,
    path: '/travel',
    component: null,
  },
  {
    label: 'Gallery',
    path: '/gallery',
    component: Gallery,
  },
  {
    label: 'Contact',
    path: '/contact',
    component: Contact,
  },
  // blog page route has to be last
  {
    label: 'Blog Page',
    path: '/post/:id/:title?',
    component: BlogPage,
    hidden: true,
  },
]

export default useRoutes

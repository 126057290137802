/**
 * Footer
 */
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from 'components/Button'
import Box from 'components/Box'
import Paper from 'components/Paper'
import Container from 'components/Container'
import Copyright from 'components/Copyright'
import SocialMediaBar from 'components/SocialMediaBar'
import SubscriptionTextfield from 'components/SubscriptionTextfield'
import useRoutes from 'utils/useRoutes'

const useStyles = makeStyles(theme => ({
  accentText: {
    color: theme.palette.primary.dark,
  },
  mobileTitle: {
    fontSize: 18,
    fontfamily: 'Bodoni MT',
  },
}))

const adminLinks = [
  { path: '/about-us', label: 'About Us' },
  { path: '/contact', label: 'Contact' },
  { path: '/lease-club', label: 'Lease Club' },
  { path: '/privacy-policy', label: 'Privacy Policy' },
]

const Footer = (props) => {
  const classes = useStyles()
  const routes = useRoutes()
  const matchesMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  // remove duplicate link
  const filteredRoutes = routes.filter(x => (
    x.path !== '/contact' && !x.hidden
  ))

  return (
    <div>
      <Paper color="secondary">
        <Container>
          <Box padding="128px 0px" xs={{ padding: '64px 20px' }}>
            {
              matchesMobile
                ? (
                  <>
                    <Box margin="0px 0px 12px">
                      <Typography className={classes.mobileTitle}>Lease Club <Typography component="span" className={clsx(classes.mobileTitle, classes.accentText)}>Luxury</Typography></Typography>
                    </Box>
                    <Box margin="0px 0px 50px">
                      <Grid container alignItems="center" spacing={3}>
                        <Grid item><Typography>Follow Us: </Typography></Grid>
                        <Grid item><SocialMediaBar /></Grid>
                      </Grid>
                    </Box>
                  </>
                )
                : null
            }
            <Grid container justify="space-between">
              <Grid xs={6} sm={4} item container direction="column" spacing={matchesMobile ? 3 : 6}>
                {
                  adminLinks.map(x => (
                    <Grid key={x.path} item>
                      <Link to={x.path}>
                        <Typography variant={matchesMobile ? 'h6' : 'h4'} color="textPrimary">{x.label}</Typography>
                      </Link>
                    </Grid>
                  ))
                }
              </Grid>
              <Grid xs={6} sm={4} item container direction="column" spacing={matchesMobile ? 3 : 6}>
                {
                  filteredRoutes.map(x => (
                    <Grid key={x.path} item>
                      <Link to={x.path}>
                        <Typography variant={matchesMobile ? 'h6' : 'h4'} color="textPrimary">{x.label}</Typography>
                      </Link>
                    </Grid>
                  ))
                }
              </Grid>
              <Box xs={{ margin: '32px 0px 0px' }}>
                <Grid xs={12} item>
                  <Typography gutterBottom variant={matchesMobile ? 'body1' : 'h3'} className={classes.accentText}>Subscribe to Lease Club</Typography>
                  <SubscriptionTextfield />
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Paper>
      <Copyright />
    </div>
  )
}

Footer.propTypes = {}
Footer.defaultProps = {}

export default Footer

// displays the featured post
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Box from 'components/Box'
import Container from 'components/Container'
import FeaturedItem from 'components/FeaturedItem'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      textAlign: 'left',
    },
  },
}))

const FeaturedPost = (props) => {
  const { posts } = props
  const classes = useStyles()
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const featuredPost = posts ? posts.find((x) => x.featuredPost) : null

  return (
    <Box
      margin="0px 0px 64px"
      xs={{ margin: '0px 0px 24px' }}
    >
      <Container>
        <Box
          margin="0px 0px 56px"
          xs={{ margin: '0px 0px 16px', padding: '0px 20px' }}
        >
          <Typography className={classes.title} variant="h2">Recent Posts</Typography>
        </Box>
        <Box xs={{ padding: '0px 20px' }}>
          {
            featuredPost ? (
              <FeaturedItem
                {...featuredPost}
                badgeText={featuredPost.page}
                src={featuredPost.heroImage.file.url}
              />
            ) : null
          }
        </Box>
      </Container>
    </Box>
  )
}

FeaturedPost.propTypes = {}
FeaturedPost.defaultProps = {}

export default FeaturedPost
